import React, { useState, useEffect } from 'react';
import Header from './components/header';
import Footer from './components/footer';
import Home from './components/home';
import ServiceProviders from './components/serviceproviders';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Import necessary components for routing
import { SignupFormDemo } from './components/signup'; // Ensure the path is correct for the Signup component
//import { LoginFormDemo } from './components/LoginFormDemo'; // Ensure to create a LoginFormDemo component similarly
//import './main.css';

function App() {
  // State to track if dark mode is enabled
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    // Get the dark mode toggle button
    const toggleButton = document.getElementById('theme-toggle');

    // Toggle the dark mode state on button click
    toggleButton.addEventListener('click', () => {
      setDarkMode((prevMode) => !prevMode);
    });

    // Cleanup event listener
    return () => {
      toggleButton.removeEventListener('click', () => {
        setDarkMode((prevMode) => !prevMode);
      });
    };
  }, []);

  return (
    <div className={darkMode ? 'dark-mode' : 'light-mode'}>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/signup" element={<SignupFormDemo />} />
          <Route path="/login" element={<SignupFormDemo />} />
          <Route path="/serviceproviders" element={<ServiceProviders />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
