import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './suggestionstyles.css'


const TestimonialsAndSuggestions = () => {

  // Slider settings for slick-carousel
  const sliderSettings = {
    dots: true,  // To show navigation dots
    infinite: true,  // Infinite loop of slides
    speed: 500,  // Slide transition speed
    slidesToShow: 1,  // Show one slide at a time
    slidesToScroll: 1,  // Scroll one slide at a time
    autoplay: true,  // Enable autoplay
    autoplaySpeed: 3000  // 3 seconds delay between slides
  };

  return (
    <>
      

      <hr />
      <div id="howitworks" class="clearfix"></div>
      <div className="how-it-works-container">
      <h2>How it Works</h2>
      <div className="steps">
        <div className="step-item">
          <div className="icon-circle">
            <i className="fas fa-map-marker-alt"></i>
          </div>
          <h3>Select a Location</h3>
          <p>Select the nearest vehicle washing center for a convenient experience.</p>
        </div>

        <div className="step-item">
          <div className="icon-circle">
            <i className="fas fa-calendar-check"></i>
          </div>
          <h3>Book a Slot</h3>
          <p>Reserve your cleaning slot for a hassle-free experience.</p>
        </div>

        <div className="step-item">
          <div className="icon-circle">
            <i className="fas fa-car"></i>
          </div>
          <h3>Get Your Car Cleaned</h3>
          <p>Relax while our experts clean your vehicle at the reserved time.</p>
        </div>
      </div>
    </div>
    <div id="testimonials" className="testimonials-main-block">
        <div className="parallax" style={{ backgroundImage: "url('images/bg/testimonials-bg.jpg')" }}>
          <div className="overlay-bg"></div>
          <div className="container-fluid">
            <div className="section text-center">
              <h4 className="section-heading">Testimonials</h4>
            </div>

            {/* Testimonials slider using react-slick */}
            <Slider {...sliderSettings} id="testimonials-slider" className="testimonials-slider">

              {/* Testimonial 1 */}
              <div className="item testimonials-block">
                <div className="testimonials-dtl text-center">
                  <p>“I have been using Lumo services for the past three years, and I am always impressed by their attention to detail. My car looks brand new after every wash. Their commitment to quality is unmatched!”</p>
                </div>
                <div className="testimonials-client">
                  <div className="testimonials-client-img">
                    <img src="images/testimonials-client-01.jpg" className="img-responsive" alt="client-01" />
                  </div>
                  <div className="testimonials-client-dtl">
                    <h6 className="client-name">Anita Kumar</h6>
                    <div className="client-since">Gold Member for 3 years</div>
                  </div>
                </div>
              </div>

              {/* Testimonial 2 */}
              <div className="item testimonials-block">
                <div className="testimonials-dtl text-center">
                  <p>“Lumo always delivers exceptional service! From the quality of their washes to the friendly staff, everything is top-notch. I highly recommend them to anyone who wants the best care for their vehicle.”</p>
                </div>
                <div className="testimonials-client">
                  <div className="testimonials-client-img">
                    <img src="images/testimonials-client-02.jpg" className="img-responsive" alt="client-02" />
                  </div>
                  <div className="testimonials-client-dtl">
                    <h6 className="client-name">Rajesh Sharma</h6>
                    <div className="client-since">Platinum Member for 2 years</div>
                  </div>
                </div>
              </div>

              {/* Testimonial 3 */}
              <div className="item testimonials-block">
                <div className="testimonials-dtl text-center">
                  <p>“I trust Lumo for all my car care needs. They always go above and beyond with their detailing, leaving my car spotless inside and out. The customer service is outstanding, and I wouldn't go anywhere else.”</p>
                </div>
                <div className="testimonials-client">
                  <div className="testimonials-client-img">
                    <img src="images/testimonials-client-03.jpg" className="img-responsive" alt="client-03" />
                  </div>
                  <div className="testimonials-client-dtl">
                    <h6 className="client-name">Meera Patel</h6>
                    <div className="client-since">Premium Member for 1 year</div>
                  </div>
                </div>
              </div>

            </Slider>
          </div>
        </div>
      </div>


      {/* <div className="suggestion-container space-bottom">
  <div className="container">
    <div className="title-area text-center">
      <span className="sub-title">
        <img className="me-2" src="assets/img/theme-img/title_icon.svg" alt="shape" />
        OUR APPROACH
        <img className="ms-2" src="assets/img/theme-img/title_icon.svg" alt="shape" />
      </span>
      <h2 className="sec-title">Streamlined Service Process</h2>
    </div>
    <div className="row gy-4 justify-content-center">
      <div className="col-xl-auto col-md-6 process-card-wrap">
        <div className="process-card">
          <div className="box-content">
            <p className="box-number">01</p>
            <div className="suggestion-icon">
              <i className="fas fa-map-marker-alt"></i>
            </div>
            <h3 className="box-title">Step 1: Select Location</h3>
            <p className="box-text">
              Begin your journey by choosing the nearest vehicle washing service center. Easily find a location that fits your needs and is convenient for you.
            </p>
            <a href="#" className="details-button">Details</a>
          </div>
        </div>
      </div>

      <div className="col-xl-auto col-md-6 process-card-wrap">
        <div className="process-card">
          <div className="box-content">
            <p className="box-number">02</p>
            <div className="suggestion-icon">
              <i className="fas fa-calendar-check"></i>
            </div>
            <h3 className="box-title">Step 2: Reserve Your Slot</h3>
            <p className="box-text">
              Once you've selected your service center, reserve a vacant slot for your vehicle cleaning. Enjoy peace of mind knowing your spot is secured for a hassle-free experience.
            </p>
            <a href="#" className="details-button">Details</a>
          </div>
        </div>
      </div>

      <div className="col-xl-auto col-md-6 process-card-wrap">
        <div className="process-card">
          <div className="box-content">
            <p className="box-number">03</p>
            <div className="suggestion-icon">
              <i className="fas fa-car"></i>
            </div>
            <h3 className="box-title">Step 3: Get Your Car Cleaned</h3>
            <p className="box-text">
              Bring your vehicle to the selected service center at your reserved time. Sit back and relax while our experts give your car the cleaning it deserves.
            </p>
            <a href="#" className="details-button">Details</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> */}

    </>
  );
};

export default TestimonialsAndSuggestions;
