import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link
import './header.css'; // Ensure you have imported the CSS file
import { HoverBorderGradient } from "../components/ui/hover-border-gradient";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      const headerOffset = 70;
      const elementPosition = section.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });

      setMenuOpen(false);
    }
  };

  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-light bgdark container-fluid fluidTop fixed-top">
        <a className="navbar-brand" href="/" style={{ marginLeft: '0px' }}>
          <img src="images/logo-white.png" alt="Logo" className="logo" />
        </a>

        <div className="d-flex align-items-center ml-auto">
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleMenu}
            aria-controls="navbarNav"
            aria-expanded={menuOpen}
            aria-label="Toggle navigation"
          >
            {menuOpen ? '✕' : '☰'}
          </button>

          <div className="d-lg-none d-flex align-items-center mobile-auth-buttons">
            <Link to="/login" className="btn-login btn-capsule-small">Login</Link>
            <Link to="/signup" className="btn-sign btn-capsule-small">Sign up</Link>
          </div>
        </div>

        <div className={`collapse navbar-collapse ${menuOpen ? 'show' : ''}`} id="navbarNav">
          <ul className="navbar-nav" style={{ marginLeft: '20px' }}>
            <li className="nav-item">
              <a className="nav-link" onClick={() => scrollToSection('home')}>Home</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" onClick={() => scrollToSection('services')}>Services</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" onClick={() => scrollToSection('whoweare')}>About Us</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" onClick={() => scrollToSection('howitworks')}>How It Works</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" onClick={() => scrollToSection('contact')}>Contact</a>
            </li>
          </ul>
        </div>
        <div className="navbar-buttons ml-auto d-none d-lg-block">
          <Link to="/login" className="btn-login btn-capsule">Login</Link>
          <Link to="/signup" className="btn-sign btn-capsule">Sign up</Link>
        </div>
      </nav>
    </header>
  );
};

export default Header;
