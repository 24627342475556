import React from 'react';
import './footer.css';

const Footer = () => {
  return (
    <footer id="footer" className="footer-main-block">
      <div className="container-fluid">
        
        {/* Top Logo */}
        <div className="row">
          <div className="col-12 text-left mb-5">
            <img
              src="images/logo-white.png"
              className="img-responsive"
              alt="logo"
              style={{ maxWidth: "150px" }}
            />
          </div>
        </div>

        {/* Main Footer Row */}
        <div className="row">
          {/* Visit Help Center */}
          <div className="col-md-3 mb-4 text-white">
            <h5 className="footer-title mb-3">Visit Help Center</h5>
            <ul className="list-unstyled">
              <li className="mb-3"><a href="#" className="text-white">Services</a></li>
              <li className="mb-3"><a href="#" className="text-white">Service Providers</a></li>
              <li className="mb-3"><a href="#" className="text-white">About us</a></li>
              <li className="mb-3"><a href="#" className="text-white">Pricing</a></li>
              <li className="mb-3"><a href="#" className="text-white">Contact us</a></li>
            </ul>
          </div>

          {/* Our Services */}
          <div className="col-md-3 mb-4 text-white">
            <h5 className="footer-title mb-3">Our Services</h5>
            <ul className="list-unstyled">
              <li className="mb-3"><a href="#" className="text-white">Vehicle Hand Wash Service</a></li>
              <li className="mb-3"><a href="#" className="text-white">Headlight Lens Restoration</a></li>
              <li className="mb-3"><a href="#" className="text-white">Scratch Removal Service</a></li>
              <li className="mb-3"><a href="#" className="text-white">Tar Removal Service</a></li>
              <li className="mb-3"><a href="#" className="text-white">Odor Elimination Service</a></li>
              <li className="mb-3"><a href="#" className="text-white">Engine Cleaning Service</a></li>
              <li className="mb-3"><a href="#" className="text-white">Valet Car Service</a></li>
            </ul>
          </div>

          {/* Opening Hours */}
          <div className="col-md-3 mb-4 text-white">
            <h5 className="footer-title mb-3 text-white">Opening Hours</h5>
            <p className="mb-3 text-white">Monday to Saturday: 9:00 AM - 8:00 PM</p>
            <p className="text-white">Sunday: Closed</p>
          </div>

          {/* Subscribe Newsletter */}
          <div className="col-md-3 mb-4 text-white">
            <h5 className="footer-title mb-3">Subscribe Newsletter</h5>
            <form className="d-flex">
              <input
                type="email"
                className="form-control me-2"
                placeholder="Enter email address"
              />
              <button type="submit" className="btn btn-primary">
                <i className="fa fa-paper-plane" aria-hidden="true"></i>
              </button>
            </form>
          </div>
        </div>

        {/* Social Media and Language Options */}
        <div className="contain">
        <div className="row align-items-left mt-4">
          <div className="col-md-6 mb-4 text-left text-md-start social-container">
          <ul className="list-unstyled d-flex justify-content-left social-icons mleft">
  <li><a href="#" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook text-white" style={{ marginRight: '6px' }}></i></a></li>
  <li><a href="#" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter text-white" style={{ marginRight: '6px' }}></i></a></li>
  <li><a href="#" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram text-white" style={{ marginRight: '6px' }}></i></a></li>
  <li><a href="#" target="_blank" rel="noopener noreferrer"><i className="fab fa-pinterest text-white"></i></a></li>
</ul>

          </div>
          <div className="col-md-6 d-flex flex-column flex-md-row justify-content-md-end align-items-left text-white mb-4">
            <ul className="list-unstyled d-flex gap-3 language-options">
              <li><a href="#" className="text-white"><i className="fas fa-globe me-2"></i>English</a></li>
              <li><a href="#" className="text-white"><i className="fas fa-map-marker-alt me-2"></i>Bangalore</a></li>
            </ul>
          </div>
        </div>
        </div>
       

        {/* Footer Bottom Section */}
        <div className="row mt-4">
          <div className="col-12 col-md-6 text-start mb-3">
            <p className="text-white mb-0">&copy; 2024 <a href="index.html" className="text-white">Lumo Limited</a>. All Rights Reserved.</p>
          </div>
          <div className="col-12 col-md-6 d-flex justify-content-md-end justify-content-left mt-2 mt-md-0">
            <ul className="list-unstyled d-flex gap-4 footer-links">
              <li><a href="#" className="text-white">Privacy</a></li>
              <li><a href="#" className="text-white">Accessibility</a></li>
              <li><a href="#" className="text-white">Terms of Service</a></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
