import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ServicesComp from './servicescomp';
import TestimonialsAndSuggestions from './testimonials';
import './home.css';

const Home = () => {
  const navigate = useNavigate();
  const [location, setLocation] = useState({ lat: null, lng: null });
  const [address, setAddress] = useState('');
  const [vehicleType, setVehicleType] = useState('');
  const [typeName, setTypeName] = useState('');
  const [brand, setBrand] = useState('');
  const [carType, setCarType] = useState('');
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [brands, setBrands] = useState([]);
  const [carTypes, setCarTypes] = useState([]);
  const addressInputRef = useRef(null);

  useEffect(() => {
    // Load Google Maps Places API
    const loadGoogleMapsScript = () => {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAJuqCQT3_uInZBGaSm0fu5pFEETjoYKNU&libraries=places`;
      script.async = true;
      script.onload = initializeAutocomplete;
      document.body.appendChild(script);
    };

    const initializeAutocomplete = () => {
      if (addressInputRef.current) {
        const autocomplete = new window.google.maps.places.Autocomplete(addressInputRef.current, {
          types: ['address'], // Restrict suggestions to addresses only
          componentRestrictions: { country: 'IN' }, // Restrict to India
        });
    
        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace();
          if (place.geometry) {
            setAddress(place.formatted_address);
            setLocation({
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            });
          }
        });
      }
    };
    

    loadGoogleMapsScript();

    // Fetch vehicle types
    fetch('https://lumolimited.com/backend/vehicles/type')
      .then(response => response.json())
      .then(data => setVehicleTypes(data))
      .catch(error => console.error("Error fetching vehicle types:", error));

    // Get user's current location
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setLocation({ lat: latitude, lng: longitude });
        fetchAddress(latitude, longitude);
      },
      (error) => console.error("Error fetching location:", error),
      { enableHighAccuracy: true }
    );
  }, []);

  const fetchAddress = async (latitude, longitude) => {
    const API_KEY = 'AIzaSyAJuqCQT3_uInZBGaSm0fu5pFEETjoYKNU';
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${API_KEY}`
      );
      if (!response.ok) throw new Error(`Network response was not ok, status: ${response.status}`);
      const data = await response.json();
      if (data.status === 'OK' && data.results.length > 0) {
        setAddress(data.results[0].formatted_address);
      } else {
        setAddress(`Error: ${data.status} - ${data.error_message || 'No results found'}`);
      }
    } catch (error) {
      console.error("Error fetching address:", error);
      setAddress('Error fetching address');
    }
  };

  const handleVehicleTypeChange = (e) => {
    const selectedTypeId = e.target.value;
    const selectedTypeName = e.target.options[e.target.selectedIndex].text;
    setVehicleType(selectedTypeId);
    setTypeName(selectedTypeName);
    setBrand('');
    setCarType('');
    fetchBrands(selectedTypeId);
  };

  const fetchBrands = (typeId) => {
    fetch(`https://lumolimited.com/backend/vehicles/type/${typeId}/brands`)
      .then(response => response.json())
      .then(data => {
        const uniqueBrands = Array.from(new Map(data.map(item => [item.brandName, item])).values());
        setBrands(uniqueBrands);
      })
      .catch(error => console.error("Error fetching brands:", error));
  };

  const fetchCarTypes = (typeId) => {
    fetch(`https://lumolimited.com/backend/vehicles/type/${typeId}/brands-or-car-types`)
      .then(response => response.json())
      .then(data => setCarTypes(data))
      .catch(error => console.error("Error fetching car types:", error));
  };

  const handleSubmit = () => {
    navigate('/serviceproviders', {
      state: {
        vehicleTypeId: vehicleType,
        vehicleTypeName: typeName,
        brandId: brand,
        brandName: brands.find(b => b.id === brand)?.brandName || '',
        carType: typeName.toLowerCase() === "car" ? carType : null,
        latitude: location.lat,
        longitude: location.lng,
        address,
      },
    });
  };

  return (
    <div id="home" className="who-we-are-main-block mt-20">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="section" style={{ paddingTop: "40px", width: "100%" }}>
              <h2 className="section-heading" style={{ width: "95%" }}>Wash Anywhere with Lumo</h2>
              <h5 style={{ textTransform: 'capitalize', marginTop: '10px', color: '#444', lineHeight: '2.6', width: "80%" }}>
                Select your vehicle type
              </h5>
              <select
                name="vehicleType"
                className="form-control responsive-input"
                value={vehicleType}
                onChange={handleVehicleTypeChange}
              >
                <option value="" disabled>Select Vehicle Type</option>
                {vehicleTypes.length > 0 ? (
                  vehicleTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.typeName || "Unnamed Type"}
                    </option>
                  ))
                ) : (
                  <option disabled>Loading vehicle types...</option>
                )}
              </select>

              {vehicleType && (
                <>
                  <h5 style={{ textTransform: 'capitalize', marginTop: '10px', color: '#444', lineHeight: '2.6', width: "80%" }}>
                    Select a Brand
                  </h5>
                  <select
                    className="form-control responsive-input"
                    value={brand}
                    onChange={(e) => {
                      setBrand(e.target.value);
                      setCarType('');
                      if (e.target.value) {
                        fetchCarTypes(vehicleType);
                      }
                    }}
                  >
                    <option value="" disabled>Select Brand</option>
                    {brands.length > 0 ? (
                      brands.map((brandItem) => (
                        <option key={brandItem.id} value={brandItem.brandName}>{brandItem.brandName}</option>
                      ))
                    ) : (
                      <option disabled>Loading brands...</option>
                    )}
                  </select>
                </>
              )}

              {vehicleType && brand && typeName.toLowerCase() === "car" && (
                <>
                  <h5 style={{ textTransform: 'capitalize', marginTop: '10px', color: '#444', lineHeight: '2.6', width: "80%" }}>
                    Select Car Type
                  </h5>
                  <select
                    className="form-control responsive-input"
                    value={carType}
                    onChange={(e) => setCarType(e.target.value)}
                  >
                    <option value="" disabled>Select Car Type</option>
                    {carTypes.length > 0 ? (
                      carTypes.map((type) => (
                        <option key={type} value={type}>{type}</option>
                      ))
                    ) : (
                      <option disabled>Loading car types...</option>
                    )}
                  </select>
                </>
              )}

              <input
                type="text"
                name="location"
                ref={addressInputRef}
                className="form-control responsive-input"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                placeholder="Enter your location..."
              />
              <input
                type="submit"
                className="btn btn-default btn-over color-9 special"
                value="Submit"
                style={{
                  paddingTop: "10px",
                  width: '30%',
                  textAlign: "center",
                  marginLeft: "0"
                }}
                onClick={handleSubmit}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="who-we-are-img">
              <img src="images/carpic-rightside.png" className="img-fluid floating-dock" alt="Car on the right side" />
            </div>
          </div>
        </div>
      </div>

      <div id="services"><ServicesComp /></div>
      <div id="testimonials"><TestimonialsAndSuggestions /></div>
    </div>
  );
};

export default Home;
