import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Slider from "react-slick";
import axios from 'axios';
import { getDistance } from 'geolib';
import './serviceproviders.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Define your Next and Prev arrow components for the slider
const NextArrow = ({ onClick }) => (
  <div className="custom-arrow next-arrow" onClick={onClick}>&#10095;</div>
);

const PrevArrow = ({ onClick }) => (
  <div className="custom-arrow prev-arrow" onClick={onClick}>&#10094;</div>
);

const ServiceProviders = () => {
  const location = useLocation();
  const { latitude, longitude, address } = location.state || {};

  const [providers, setProviders] = useState([]);
  const [showroomCount, setShowroomCount] = useState(0);
  const [selectedShowroom, setSelectedShowroom] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const [availableSlots, setAvailableSlots] = useState([]);

  // Function to extract city from address
  const extractCityFromAddress = (address) => {
    if (!address) return null;

    const lowerAddress = address.toLowerCase();
    const cityAliases = {
      "Hyderabad": ["hyderabad"],
      "Bangalore": ["bangalore", "bengaluru"],
      "Chennai": ["chennai"],
      "Mumbai": ["mumbai", "bombay"],
      "Delhi": ["delhi", "new delhi"],
      "Kolkata": ["kolkata", "calcutta"],
      "Pune": ["pune"],
      "Ahmedabad": ["ahmedabad"]
    };

    for (let [standardCity, aliases] of Object.entries(cityAliases)) {
      if (aliases.some(alias => lowerAddress.includes(alias))) {
        return standardCity;
      }
    }

    const addressParts = address.split(',').map(part => part.trim());
    return addressParts.length >= 2 ? addressParts[addressParts.length - 2] : null;
  };

  const userCity = extractCityFromAddress(address);

  useEffect(() => {
    const fetchProviders = async () => {
      try {
        const response = await axios.get('https://lumolimited.com/backend/services/provider');
        const dataWithDistance = response.data.map(provider => {
          const filteredShowrooms = provider.Showrooms.filter(showroom => {
            return showroom.city && showroom.city.toLowerCase() === userCity?.toLowerCase();
          }).map(showroom => {
            const hasCoordinates = showroom.latitude && showroom.longitude && latitude && longitude;
            const distance = hasCoordinates
              ? getDistance(
                { latitude, longitude },
                { latitude: showroom.latitude, longitude: showroom.longitude }
              )
              : null;
            const timeEstimate = distance ? Math.ceil(distance / 500) : null;
            return {
              ...showroom,
              distance: distance || 'N/A',
              timeEstimate: timeEstimate || 'N/A',
            };
          });

          return { ...provider, Showrooms: filteredShowrooms };
        }).filter(provider => provider.Showrooms.length > 0);

        setProviders(dataWithDistance);

        const totalShowrooms = dataWithDistance.reduce((acc, provider) => acc + provider.Showrooms.length, 0);
        setShowroomCount(totalShowrooms);

      } catch (error) {
        console.error("Error fetching service providers:", error);
      }
    };
    fetchProviders();
  }, [latitude, longitude, userCity]);

  const openPopup = (showroom) => {
    setSelectedShowroom(showroom);
    setIsPopupOpen(true);
    const today = new Date().toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase();
    calculateAvailableSlots(showroom.workingHours, showroom.breakTimes, today);
  };


  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const calculateAvailableSlots = (workingHours, breakTimes, selectedDay = new Date().toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase()) => {
    const slots = [];
  
    // Get start and end working hours for the selected day
    const workStart = workingHours[selectedDay]?.start;
    const workEnd = workingHours[selectedDay]?.end;
  
    if (workStart === "Closed" || workEnd === "Closed") {
      setAvailableSlots([]); // No slots if closed
      return;
    }
  
    // Create date objects without any time zone conversion issues
    const [startHour, startMinute] = workStart.split(":").map(Number);
    const [endHour, endMinute] = workEnd.split(":").map(Number);
    const start = new Date(1970, 0, 1, startHour, startMinute);
    const end = new Date(1970, 0, 1, endHour, endMinute);
  
    // Get break start and end times without any time zone conversion issues
    const [breakStartHour, breakStartMinute] = breakTimes?.lunch?.start ? breakTimes.lunch.start.split(":").map(Number) : [];
    const [breakEndHour, breakEndMinute] = breakTimes?.lunch?.end ? breakTimes.lunch.end.split(":").map(Number) : [];
    const breakStart = breakStartHour !== undefined ? new Date(1970, 0, 1, breakStartHour, breakStartMinute) : null;
    const breakEnd = breakEndHour !== undefined ? new Date(1970, 0, 1, breakEndHour, breakEndMinute) : null;
  
    // Create slots before and after the break if it exists
    if (breakStart && breakEnd && start < breakStart && breakEnd < end) {
      createSlots(start, breakStart, slots);    // Slots before lunch
      createSlots(breakEnd, end, slots);        // Slots after lunch
    } else {
      // No lunch break or invalid break, create full-day slots
      createSlots(start, end, slots);
    }
  
    setAvailableSlots(slots);
  };
  
  const createSlots = (start, end, slots) => {
    let currentTime = new Date(start);
  
    while (currentTime < end) {
      const nextTime = new Date(currentTime);
      nextTime.setHours(currentTime.getHours() + 1); // 1-hour slots
  
      slots.push({
        startTime: currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
        endTime: nextTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
        isAvailable: true,
      });
  
      currentTime = nextTime;
    }
  };
  
  







  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: Math.min(3, showroomCount),
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: Math.min(2, showroomCount), infinite: true }
      },
      {
        breakpoint: 600,
        settings: { slidesToShow: 1, infinite: true }
      }
    ]
  };

  return (
    <div>
      <div id="providers" className="clear-fix"></div>
      <div className="container-fluid service-slider">
        <h4 className="section-heading text-center mb-5" style={{ marginTop: "50px" }}>
          Our Service Providers
        </h4>

        <div className="slider-wrapper">
          <Slider {...sliderSettings}>
            {providers.map(provider => (
              provider.Showrooms.map(showroom => (
                <div key={showroom.id} className="card-wrapper">
                  <div className="card">
                    <img
                      src={showroom.images?.[0] || '/path/to/fallback-image.jpg'}
                      className="card-img-top universal-img"
                      alt={`${provider.name} Service`}
                      onError={(e) => { e.target.onerror = null; e.target.src = "/path/to/fallback-image.jpg"; }}
                    />
                    <div className="card-body">
                      <div className="card-header">
                        <h5 className="card-title">{provider.name}</h5>
                        <div className="star-rating">
                          <span>⭐ 4.5</span>
                        </div>
                      </div>
                      <div className="location-duration-row">
                        <p className="card-text location-text">
                          {showroom.address ? `${showroom.address}, ${showroom.city}` : 'Location not available'}
                        </p>
                        <p className="card-text time-text">
                          {showroom.timeEstimate !== 'N/A' ? `${showroom.timeEstimate} mins` : 'N/A'}
                        </p>
                      </div>
                      <div className="service-distance-row">
                        <p className="card-text service-text">
                          {showroom.ServiceTypes?.[0]?.serviceName || 'Exteriar Wash'}
                        </p>
                        <p className="card-text distance-text">
                          {showroom.distance !== 'N/A' ? `${(showroom.distance / 1000).toFixed(2)} km` : 'N/A'}
                        </p>
                      </div>

                      <div className="button-group">
                        <button className="btn btn-primary" onClick={() => openPopup(showroom)}>Book Now</button>
                        <button className="btn btn-secondary">View Details</button>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ))}
          </Slider>
        </div>
      </div>

      {/* Popup Box */}
      {isPopupOpen && (
        <div className="popup-overlay">
          <div className="popup-content">
            <button className="close-btn" onClick={closePopup}>×</button>
            <h5 className="text-center">Select Available Slot</h5>
            <hr />
            <input type="date" value={selectedDate} onChange={handleDateChange} className="text-center" />
            <hr />
            <div className="slots-container">
              {availableSlots.map((slot, index) => (
                <button
                  key={index}
                  className="slot-btn"
                >
                  {`${slot.startTime} - ${slot.endTime}`}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}


    </div>
  );
};

export default ServiceProviders;
