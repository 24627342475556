import React from 'react';
import './services.css';

const ServicesComp = () => {
    return (
        <div id="services" className="services-main-block">
            <div className="container-fluid">
                <div className="section text-center">
                    <h4 className="section-heading">Our Services</h4>
                    <p className="sub-heading" style={{ marginBottom: "30px" }}>
                        Providing premium car care with attention to every detail.
                    </p>
                </div>

                <div className="suggestion-container">
                    <div className="suggestion-item" id="hand-wash">
                        <div className="suggestion-content">
                            <div>
                                <div className="suggestion-title">Vehicle Hand Wash</div>
                                <p>Our hand wash leaves your car scratch-free with a flawless shine.</p>
                                <a href="services-details.html" className="details-button">Details</a>
                            </div>
                            <div className="suggestion-icon">
                                <img src="images/icons/service-01.png" alt="Vehicle Hand Wash" className="img-responsive" />
                            </div>
                        </div>
                    </div>

                    <div className="suggestion-item" id="headlight-restoration">
                        <div className="suggestion-content">
                            <div>
                                <div className="suggestion-title">Headlight Lens Restoration</div>
                                <p>We restore headlight clarity for better visibility and safety</p>
                                <a href="services-details.html" className="details-button">Details</a>
                            </div>
                            <div className="suggestion-icon">
                                <img src="images/icons/service-02.png" alt="Headlight Restoration" className="img-responsive" />
                            </div>
                        </div>
                    </div>

                    <div className="suggestion-item" id="scratch-removal">
                        <div className="suggestion-content">
                            <div>
                                <div className="suggestion-title">Scratch Removal</div>
                                <p>Our scratch removal restores your car’s flawless look.</p>
                                <a href="services-details.html" className="details-button">Details</a>
                            </div>
                            <div className="suggestion-icon">
                                <img src="images/icons/service-03.png" alt="Scratch Removal" className="img-responsive" />
                            </div>
                        </div>
                    </div>

                    <div className="suggestion-item" id="tar-removal">
                        <div className="suggestion-content">
                            <div>
                                <div className="suggestion-title">Tar Removal</div>
                                <p>We safely remove tough tar and grime, preserving your vehicle's paint and finish.</p>
                                <a href="services-details.html" className="details-button">Details</a>
                            </div>
                            <div className="suggestion-icon">
                                <img src="images/icons/service-04.png" alt="Tar Removal" className="img-responsive" />
                            </div>
                        </div>
                    </div>

                    <div className="suggestion-item" id="odor-elimination">
                        <div className="suggestion-content">
                            <div>
                                <div className="suggestion-title">Odor Elimination</div>
                                <p>Our treatments remove persistent odors, leaving your vehicle’s interior fresh and pleasant.</p>
                                <a href="services-details.html" className="details-button">Details</a>
                            </div>
                            <div className="suggestion-icon">
                                <img src="images/icons/service-05.png" alt="Odor Elimination" className="img-responsive" />
                            </div>
                        </div>
                    </div>

                    <div className="suggestion-item" id="engine-cleaning">
                        <div className="suggestion-content">
                            <div>
                                <div className="suggestion-title">Engine Cleaning</div>
                                <p>Our engine cleaning removes grime, boosting performance and extending engine life.</p>
                                <a href="services-details.html" className="details-button">Details</a>
                            </div>
                            <div className="suggestion-icon">
                                <img src="images/icons/service-06.png" alt="Engine Cleaning" className="img-responsive" />
                            </div>
                        </div>
                    </div>

                    <div className="suggestion-item" id="hazardous-cleaning">
                        <div className="suggestion-content">
                            <div>
                                <div className="suggestion-title">Hazardous Material Cleaning</div>
                                <p>We safely clean and dispose of hazardous materials to protect your vehicle and the environment.</p>
                                <a href="services-details.html" className="details-button">Details</a>
                            </div>
                            <div className="suggestion-icon">
                                <img src="images/icons/service-07.png" alt="Hazardous Material Cleaning" className="img-responsive" />
                            </div>
                        </div>
                    </div>

                    <div className="suggestion-item" id="valet-service">
                        <div className="suggestion-content">
                            <div>
                                <div className="suggestion-title">Valet Service</div>
                                <p>We provide valet service, picking up and delivering your vehicle after washing and detailing.</p>
                                <a href="services-details.html" className="details-button">Details</a>
                            </div>
                            <div className="suggestion-icon">
                                <img src="images/icons/service-08.png" alt="Valet Service" className="img-responsive" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServicesComp;
